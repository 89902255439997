
let saveToken=(token)=>{
    localStorage.setItem('token',token)
}

let logOut=()=>{
    localStorage.removeItem('token')
    localStorage.removeItem('emailUser');
}

let isLogged=()=>{
    if(typeof localStorage.token !== 'undefined' && localStorage.token!=='') return true;
    else return false;

}

let getToken=()=>{
    return localStorage.getItem('token')
}

let isMdpProv= ()=>{
    const isMdpProv= localStorage.getItem('isMdpProv') ? localStorage.getItem('isMdpProv') : 'false'; //si isMdpProv n'est pas présent implicite false
    return isMdpProv==='true'? true : false;
}


let setEmailUser= emailUser=>{
    localStorage.setItem('emailUser',emailUser);
}

let setRoleUser= roleUser=>{
    localStorage.setItem('roleUser',roleUser);
}

let getRoleUser= ()=>{
    return localStorage.getItem('roleUser');
}

let getEmailUser=()=>{
    return localStorage.getItem('emailUser');
}

export const accountService={
    saveToken,logOut,isLogged,getToken,isMdpProv,setEmailUser,getEmailUser,setRoleUser,getRoleUser,
}

