import React, { useState, useRef, useEffect} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useFormik} from 'formik';
import * as Yup from 'yup';

import { accountService } from '@/_services/account.service';
import { axiosService } from '@/_services/account.axios';
import Wait from '@/components/Wait';

const ValidAdhesion = () => {

    const navigate = useNavigate()

    // recup idADherent et data adhérent

    const {idAdherent,nom,prenom,situation} = useParams();
    // console.log(idAdherent);


    //Validation des pouvoirs de validation
        useEffect(() => {
            if(accountService.isLogged()){
    
                //initialisation options requète API
                const options = {
                    values: {
                        domaine:'front',
                        action:'userCan',
                        page: 'valideAdh',
                    },
                    OKData: (data) => {     //action callback déclanchée si tout est OK 
                        if(!data.valide){
                            alert('Vous ne pouvez pas accéder à cette fonction');
                            navigate('/adherents')
                        }
                    }
                }
    
                //lancement requète API
                axiosService.postAxios(options);
    
            return ;
            }
    
        },[])

    //valeurs par défaut pour le formulaire
    const [initialValues, setInitialValues] = useState({
        idAdherent: idAdherent,
        datePaiement:'',
        montant:'',
        modePaiement:'',
        nom: nom,
        prenom: prenom,
        situation: situation,

    })
    
    //formulaire Formik
    const formik = useFormik({
        initialValues: initialValues,
        enableReinitialize: true,

        validationSchema: Yup.object().shape({
            montant: Yup.number()
            .required('Montant obilgatoire'),
            datePaiement: Yup.date('Date paiement erronnée')
            .required('date paiement obligatoire'),
            modePaiement: Yup.string()
            .required('Mode paiement obligatoire'),
            noRemise: Yup.number('no de remise invalide')
        }),

        //MAJ BDD par requète API
        onSubmit: values => {
            values.domaine = 'front';    //Domaine API
            values.action = 'setNewSituation';       // action API
            values.motif= formik.initialValues.situation=== 'demandee' ? 'validee' : 'renouvelee';

            const options = {
                values: values,
                errorData: (data)=>{
                    let span = document.getElementById("message");
                    span.firstChild && span.firstChild.remove(); //supprimer l'ancien message d'anomalie
                    let txt = document.createTextNode(data.message);
                    span.appendChild(txt);          
                },
                OKData: (data) => {
                    //action si OK
                    navigate('/adherents');
                    console.log(data);
                }
            }

            axiosService.postAxios(options);
        }
})

    return (
        <>
            <form data-form-type="other" className='mb-3 offset-2' onSubmit={formik.handleSubmit}>
                <fieldset>
                    {
                        formik.initialValues.situation === 'demandee' 
                        ?
                        <legend className='mb-2  text-center col-6'>{'Valider  l\'adhésion de '+ formik.initialValues.prenom + ' ' + formik.values.nom }</legend>
                        :
                        <legend className='mb-2  text-center col-6'>{'Renouveler  l\'adhésion de '+ formik.initialValues.prenom + ' ' + formik.values.nom }</legend>
                    }
                    <div className='mb-3 offset-2 col-6 text-center'>
                        <span className='text-danger ms-1' id='message' name='message'></span>
                    </div>
                    <div className="mb-3 col-3">
                        <label for="datePaiement" className="form-label">date de paiement</label>
                        <input type="date" className="form-control" id="datePaiement" 
                            name='datePaiement'
                            onChange={formik.handleChange}
                            value={formik.values.datePaiement}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.datePaiement && formik.errors.datePaiement && <span className='text-danger ms-1'>{formik.errors.datePaiement}</span>}
                    </div>
                    <div className="mb-3 col-3">
                        <label for="modePaiement*" className="form-label">Mode de paiement</label>
                        <select type="text" className="form-control" id="modePaiement*" 
                            name='modePaiement'
                            onChange={formik.handleChange}
                            value={formik.values.modePaiement}
                          >
                          <option></option>
                          <option value='especes'>Espèces</option>
                          <option value='cheque'>Chèque</option>
                          <option value='virement'>Virement</option>
                          <option value='gratuit'>Gratuit</option>
                          </select>

                        {formik.touched.modePaiement && formik.errors.modePaiement && <span className='text-danger ms-1'>{formik.errors.modePaiement}</span>}
                    </div>
                        <div className="mb-3 col-3">
                        <label for="noremise" className="form-label">Numéro de remise</label>
                        <input type="text" className="form-control" id="noRemise" 
                            name='noRemise'
                            onChange={formik.handleChange}
                            value={formik.values.noRemise}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.noRemise && formik.errors.noRemise && <span className='text-danger ms-1'>{formik.errors.noRemise}</span>}
                    </div>

                    <div className="mb-3 col-3">
                        <label for="montant" className="form-label">Montant</label>
                        <input type="text" className="form-control" id="montant" 
                            name='montant'
                            onChange={formik.handleChange}
                            value={formik.values.montant}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.montant && formik.errors.montant && <span className='text-danger ms-1'>{formik.errors.montant}</span>}
                    </div>
                    <button type="submit" className="button md-3" onClick={formik.handleSubmit}>Envoyez</button>
                    </fieldset>
            </form>

        </>
    );
};

export default ValidAdhesion;